<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<!-- 厂商管理 -->
		<el-card>
		  <!-- 搜索框 -->
		  <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
			<el-form-item label="名称" label-width="50px" prop="keyword">
			  <el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable style="width: 230px" />
			</el-form-item>
			<el-form-item label="角色编码" label-width="80px" prop="code">
			  <el-input v-model="queryForm.code" placeholder="请输入角色编码" type="text" clearable style="width: 230px" />
			</el-form-item>
			<el-form-item>
			  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
			  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
			</el-form-item>
		 </el-form>
		 <!--自定义刷新组件-->
		 <el-row :gutter="10" class="mb-1">
			<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
			<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
		  </el-row>
		 <!--表格-->
		  <el-table
			v-loading="loading"
			size="small"
			:data="tree"
			element-loading-text="Loading"
			highlight-current-row
			row-key="id"
			:tree-props="{children: 'children'}"
			:default-expand-all="true"
			style="width: 100%"
		  >
			<el-table-column label="ID" width="80" align="center">
			  <template slot-scope="scope">
				{{ scope.row.id }}
			  </template>
			</el-table-column>
			<el-table-column label="角色名称" min-width="110" align="center">
			  <template slot-scope="scope">
				<span>{{ scope.row.name }}</span>
			  </template>
			</el-table-column>
			<el-table-column label="角色编码" min-width="110" align="center">
			  <template slot-scope="scope">
				{{ scope.row.code }}
			  </template>
			</el-table-column>
			<el-table-column label="数据权限" min-width="120" align="center">
			  <template slot-scope="scope">
				<template v-for="item in dataScopes">
				  <el-tag v-if="scope.row.ds_type === item.value" :key="item.value" type="primary" size="mini">{{ item.label }}</el-tag>
				</template>
			  </template>
			</el-table-column>
			<el-table-column label="状态" min-width="50" align="center">
			  <template slot-scope="scope">
				<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
				<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
			  </template>
			</el-table-column>
			<el-table-column align="center" prop="utime" label="创建时间" min-width="110">
			  <template slot-scope="scope">
				<i class="el-icon-time" />
				<span>{{ scope.row.utime }}</span>
			  </template>
			</el-table-column>
			<el-table-column label="操作" min-width="150" align="center">
			  <template slot-scope="scope">
				<el-tooltip class="item" effect="dark" content="编辑" placement="top">
				  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
                </el-tooltip>
				<el-tooltip class="item" effect="dark" content="删除" placement="top">
				  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="授权" placement="top">
				  <el-button type="warning" icon="el-icon-plus" size="mini" circle @click="handlePermission(scope.row.id)"></el-button>		
				</el-tooltip>
				<el-tooltip class="item" effect="dark" content="数据权限" placement="top">
				  <el-button type="info" icon="el-icon-circle-check" size="mini" circle @click="handleDataScope(scope.row)"></el-button>		
				</el-tooltip>
			  </template>
			</el-table-column>
		  </el-table>
<!-- 		  <pagination
			:total="total"
			auto-scroll
			:page.sync="queryForm.page"
			:limit.sync="queryForm.limit"
			@pagination="query"
		  /> -->
		</el-card>
		 <!-- 编辑-->
		  <el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
			   <el-form-item label="上级分类" prop="pid">
					<el-select v-model="form.pid" placeholder="请选择上级分类" size="medium" class="w-100">
					  <el-option label="顶级分类" :value="0"></el-option>
					  <el-option v-for="(item,index) in list" :key="index"
						:label="item | tree"
						:value="item.id">
					  </el-option>
					</el-select>
			  </el-form-item>
			  <el-form-item label="角色名称" prop="name">
				<el-input v-model="form.name" placeholder="请输入角色名称" type="text" clearable :disabled="dsShow" />
			  </el-form-item>
			  <el-form-item label="角色编码" prop="code">
				<el-input v-model="form.code" placeholder="请输入角色编码" type="text" clearable :disabled="dsShow" />
			  </el-form-item>
			  <el-form-item v-if="dsShow" label="数据权限" prop="ds_type">
				<el-select v-model="form.ds_type" placeholder="请选择数据权限" style="width: 100%">
				  <el-option v-for="item in dataScopes" :key="item.value" :label="item.label" :value="item.value" />
				</el-select>
			  </el-form-item>
			  <el-form-item v-if="!dsShow" label="备注" prop="remark">
				<el-input v-model="form.remark" placeholder="请输入备注" type="textarea" maxlength="50" show-word-limit/>
			  </el-form-item>
			 <el-form-item v-if="!dsShow" label="状态" prop="status">
				<el-radio-group v-model="form.status">
					<el-radio :label="1">使用</el-radio>
					<el-radio :label="2">禁用</el-radio>
				</el-radio-group>
			  </el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
			  <el-button size="mini" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
			</div>
		  </el-dialog>
		  
		<!-- 授权 dialog-->
		<el-dialog :visible.sync="permOpen" :close-on-click-modal="false" width="600px" title="授权">
		  <div class="dialog-main-tree">
			<el-tree
			  ref="permission"
			  :data="menuOptions"
			  :props="defaultProps"
			  expand-on-click-node
			  node-key="id"
			  empty-text="加载中，请稍后"
			  default-expand-all
			  show-checkbox
			/>
		  </div>
		  <div slot="footer" class="dialog-footer">
			<el-button size="mini" @click="permCancel">取 消</el-button>
			<el-button type="primary" size="mini" @click="permSubmit">更 新</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		provide() {
		  return {
		    loadData: []
		  }
		},
		data() {
			return {
				preUrl:"admin_role",
				queryForm: {
					page: 1,
					limit: 100,
					name: '',
					code: '',
					status: 0
				},
				list: [],
				statusOptions: [],
				dataScopes: [ // 数据权限类型
					{
					  label: '仅本人数据',
					  value: 5
					},
					{
					  label: '所在单位（部门）数据',
					  value: 4
					},
					{
					  label: '所在单位（部门）及以下数据',
					  value: 3
					},
					{
					  label: '按明细设置',
					  value: 2
					},
					{
					  label: '全部数据',
					  value: 1
					}
				],
				open: false,
			    dsShow: false, // 是否数据权限展示
			    title: '',
				defaultProps: {
				children: 'children',
				label: 'name'
			  },
			  roleId:0,
			  permOpen:false,
			  menuOptions:[],
			  form:{
				  pid:0,
				  name:'',
				  code:'',
				  ds_type:'',
				  remark:'',
				  status:1
			  },
			  rules:{
				  pid: [
				  	{ required: true, message: '请选择上级分类', trigger: 'blur' }
				  ],
				  name: [
					{ required: true, message: '请输入角色名称', trigger: 'blur' }
				  ],
				  code: [
					{ required: true, message: '请输入角色编码', trigger: 'blur' }
				  ],
				  ds_type: [
					{ required: true, message: '请选择数据权限', trigger: 'blur',type: 'number' }
				  ]
			  },
			  tree:[],
			}
		},
		mounted() {
			this.getRules()
		},
		methods:{
			getList() {
				this.loading = true
				this.axios.get(`/manage/${this.preUrl}/list`, {
				  params: this.queryForm
				}).then(res => {
				  this.total = res.total
				  this.list = res.data
				  this.tree = res.tree
				  this.loading = false
				}).catch(err => {
				  this.loading = false
				})
			},
			getRules(){
			  this.axios.get('/manage/operation/list').then(res=>{
				if(res.status){
				  this.menuOptions = res.data
				}
			  })
			},
			// 新增
			handleAdd(){
			    this.reset()
			    this.title = '新增角色'
				this.dsShow = false
			    this.open = true
			},
			reset() {
			  this.form = {
				pid:0,
			    name:'',
			    code:'',
			    ds_type:'',
			    remark:'',
			    status:1
			  }
			  this.resetForm('form')
			},
		   // 取消按钮
			cancel() {
			  this.open = false
			  this.reset()
			},
			handleEdit(row){
			  this.reset()
			  this.dsShow = false
			  this.open = true
			  this.title = '修改角色'
			  this.form = JSON.parse(JSON.stringify(row))
			  if(row.ds_type == 0){
				  this.form.ds_type = ''
			  }
			},
			permCancel(){
			  this.permOpen = false
			},
			permSubmit(){
			  this.permOpen = false
			  const menuIds = this.getTreeCheckedKeys('permission')
			  this.axios.post('/manage/admin_role/setRules',{
				id:this.roleId,
				ids:menuIds
			  }).then(res=>{
				if(res.status){
				   this.$message.success('授权成功')
				   this.permOpen = false
				}else{
				   this.$message.error(result.msg)
				}
			  })
			},
			handleDataScope(row){
			  this.reset()
			  this.dsShow = true
			  this.open = true
			  this.title = '数据权限'
			  const roleInfo = JSON.parse(JSON.stringify(row)) // 对象深拷贝
			  this.form = roleInfo
			  // 数据权限回显
			  if(this.form.ds_type == 0){
			  	 this.form.ds_type = ''
			  }
			  if (this.form.ds_type === 2) {
				const orgIds = this.form.ds_scope.split(',')
				orgIds.forEach(orgId => {
				  this.$nextTick(() => { // 选中tree节点
					this.setTreeNodeCheck('org', orgId)
				  })
				})
			  }
			},
			handlePermission(id) {
			  if (this.$refs.permission !== undefined) {
				this.$refs.permission.setCheckedKeys([])
			  }
			  this.permOpen = true
			  this.roleId = id
			  this.axios.get('/manage/admin_role/getRules',{
				params:{
				  id:id
				}
			  }).then(res=>{
				if(res.status){
				  if(res.data){
					res.data.forEach(menuId => {
					  this.$nextTick(() => {
						this.setTreeNodeCheck('permission', menuId) // 选中tree节点
					  })
					})
				  }
				}
			  })
			},
		},
		filters: {
		  tree(item) {
			if (item.level == 0) {
			  return item.name
			}
			let str = ''
			for (let i = 0; i < item.level; i++) {
			  str += i == 0 ? '|--' : '--'
			}
			return str + ' ' +item.name;
		  }
		},
	}
</script>

<style>

</style>